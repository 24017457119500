<template>
  <VContainer
    fluid
  >
    <VRow>
      <VCol cols="12">
        <SearchBar
          update-query
          :loading="loading"
        />
      </VCol>
    </VRow>
    <VRow
      v-if="isEmpty"
      class="mt-2"
    >
      <VCol>
        <div>
          <h2>
            По вашему запросу ничего не найдено
          </h2>
          <p class="tt-text-body-1 tt-light-mono-46--text">
            Убедитесь, что все слова поискового запроса написаны без ошибок или попробуйте использовать другие ключевые
            слова.
          </p>
        </div>
      </VCol>
    </VRow>
    <VRow
      v-else
      class="mt-2"
    >
      <VCol
        v-if="!isEmpty"
        cols="12"
      >
        <VTabs
          v-model="selectedTab"
          color="black"
          class="tabs-bar"
          active-class="active-tab-background"
          background-color="transparent"
          data-test="tab-items"
        >
          <VTabsSlider
            color="black"
          />
          <div
            v-for="tab in activeTabNames"
            :key="tab.text"
            class="tabs-bar-tab tabs-bar-tab--underline"
          >
            <VTab
              :ripple="false"
              :href="`#${tab.value}`"
              class="justify-start px-0 tabs-bar-tab-hover--none text-capitalize
              tt-text-title-2 fill-height align-start"
              :data-test="`tab-` + tab.value"
            >
              {{ tab.text }}
            </VTab>
          </div>
        </VTabs>
        <VTabsItems v-model="selectedTab">
          <VTabItem
            v-for="tab in activeTabNames"
            :key="tab.text"
            :value="tab.value"
            :transition="false"
            :data-test="`tab-content-` + tab.value"
          >
            <VContainer class="px-0 py-9">
              <!--              LEVELS-->
              <template v-if="showLevels">
                <VRow dense>
                  <VCol>
                    <div class="tt-text-subtitle">
                      Уровни
                    </div>
                  </VCol>
                </VRow>
                <VRow dense>
                  <VCol cols="12">
                    <VList
                      two-line
                      class="pa-0"
                    >
                      <template v-for="(level, index) in levelsData">
                        <SearchListItem
                          :key="level.id"
                        >
                          <template #title>
                            <RouterLink
                              :to="{name : routeNames.R_LEVEL_ID, params : {id : level.location.id}}"
                              class="text-decoration-none"
                            >
                              {{ level.location.name }}
                            </RouterLink>
                          </template>
                          <template #subtitle>
                            {{ level.name }}
                          </template>
                        </SearchListItem>
                        <VDivider
                          v-if="index!==levelsData.length-1"
                          :key="index"
                          class="my-2"
                        />
                      </template>
                    </VList>
                  </VCol>
                </VRow>
              </template>
              <!--              TASK-->
              <template v-if="showTasks">
                <VRow dense>
                  <VCol>
                    <div class="tt-text-subtitle">
                      Задачи
                    </div>
                  </VCol>
                </VRow>
                <VRow dense>
                  <VCol cols="12">
                    <VList
                      two-line
                      class="pa-0"
                    >
                      <template v-for="(task, index) in tasksData">
                        <SearchListItem
                          :key="task.id"
                          :icon="taskIconMap[task.type]"
                          icon-color="tt-light-blue"
                        >
                          <template #title>
                            <RouterLink
                              class="text-decoration-none"
                              :to="{name : routeNames.R_LEVEL_ID,params : {id : task.level.location.id}}"
                            >
                              {{ task.level.location.name }}
                            </RouterLink>
                            <span class="mx-1">
                              /
                            </span>
                            <RouterLink
                              :to="{name : routeNames.R_TASK_LEVEL,params : {id : task.level.id}}"
                              class="text-decoration-none"
                            >
                              {{ task.level.name }}
                            </RouterLink>
                          </template>
                          <template #subtitle>
                            {{ task.name }}
                          </template>
                        </SearchListItem>
                        <VDivider
                          v-if="index!==tasksData.length-1"
                          :key="index"
                          class="my-2"
                        />
                      </template>
                    </VList>
                  </VCol>
                </VRow>
              </template>
              <!--              CONTENT-->
              <template v-if="showContent">
                <VRow dense>
                  <VCol>
                    <div class="tt-text-subtitle">
                      Контент
                    </div>
                  </VCol>
                </VRow>
                <VRow dense>
                  <VCol cols="12">
                    <VList
                      two-line
                      class="pa-0"
                    >
                      <template v-for="(content, index) in contentData">
                        <SearchListItem
                          :key="content.id"
                          icon-color="tt-light-purple"
                          :icon="contentTypeIcons[content.type]"
                        >
                          <template #title>
                            <RouterLink
                              class="text-decoration-none"
                              :to="{name : routeNames.R_LEVEL_ID,params : {id : content.task.level.location.id}}"
                            >
                              {{ content.task.level.location.name }}
                            </RouterLink>
                            <span class="mx-1">
                              /
                            </span>
                            <RouterLink
                              class="text-decoration-none"
                              :to="{name : routeNames.R_TASK_LEVEL,params : {id : content.task.level.id}}"
                            >
                              {{ content.task.level.name }}
                            </RouterLink>
                            <span class="mx-1">
                              /
                            </span>
                            <RouterLink
                              :to="{name : routeNames.R_CONTENT_LIST,params : {id : content.task.id}}"
                              class="text-decoration-none"
                            >
                              {{ content.task.name }}
                            </RouterLink>
                          </template>
                          <template #content>
                            <Component
                              :is="componentsMap[content.type]"
                              :data="content"
                            />
                          </template>
                        </SearchListItem>
                        <VDivider
                          v-if="index!==contentData.length-1"
                          :key="index"
                          class="my-2"
                        />
                      </template>
                    </VList>
                  </VCol>
                </VRow>
              </template>
            </VContainer>
          </VTabItem>
        </VTabsItems>
      </VCol>
    </VRow>
    <!-- Поиск по контенту -->
  </VContainer>
</template>

<script>
import * as snamiApi from '@/services/backend/snami';
import SearchListItem from '@/components/search/SearchListItem.vue';
import SearchBar from '@/components/shared/SearchBar.vue';
import { contentTypeIcons, taskIconMap } from '@/constants';
import * as routeNames from '@/plugins/vue-router/constants';

export default {
  name: 'Search',
  components: {
    SearchBar,
    SearchListItem,
    ContentHtml: () => import('@/components/content/ContentHtml.vue'),
    ContentImage: () => import('@/components/content/ContentImage.vue'),
    ContentDocument: () => import('@/components/content/ContentDocument.vue'),
    ContentQrCode: () => import('@/components/content/ContentQrCode.vue'),
    ContentVideo: () => import('@/components/content/ContentVideo.vue'),
    ContentMap: () => import('@/components/content/ContentMap.vue'),
    ContentEnterString: () => import('@/components/content/ContentEnterString.vue'),
    ContentAddPhoto: () => import('@/components/content/ContentAddPhoto.vue'),
    ContentYesNo: () => import('@/components/content/ContentYesNo.vue'),
    ContentOneWord: () => import('@/components/content/ContentOneWord.vue'),
    ContentSelectOne: () => import('@/components/content/ContentSelectOne.vue'),
    ContentSelectMany: () => import('@/components/content/ContentSelectMany.vue'),
    ContentVote: () => import('@/components/content/ContentVote.vue'),
    ContentRate: () => import('@/components/content/ContentRate.vue'),
    ContentOffer: () => import('@/components/content/ContentOffer.vue'),
    ContentContact: () => import('@/components/content/ContentContact.vue'),
  },
  data: () => ({
    taskIconMap,
    contentTypeIcons,
    routeNames,
    searchResult: {},
    loading: false,
    selectedTab: 'all',
    componentsMap: {
      HTML: 'ContentHtml',
      IMAGE: 'ContentImage',
      DOCUMENT: 'ContentDocument',
      QR_CODE: 'ContentQrCode',
      VIDEO: 'ContentVideo',
      MAP: 'ContentMap',
      ENTER_STRING: 'ContentEnterString',
      ADD_PHOTO: 'ContentAddPhoto',
      YES_NO: 'ContentYesNo',
      ONE_WORD: 'ContentOneWord',
      SELECT_ONE: 'ContentSelectOne',
      SELECT_MANY: 'ContentSelectMany',
      VOTE: 'ContentVote',
      RATE: 'ContentRate',
      OFFER: 'ContentOffer',
      CONTACT: 'ContentContact',
    },
    levelsData: [],
    tasksData: [],
    contentData: [],
    tabNames: [
      {
        text: 'Все',
        path: 'all',
        value: 'all',
      }, {
        text: 'Уровни',
        path: 'levels',
        value: 'levels',
      }, {
        text: 'Задания',
        path: 'tasks',
        value: 'tasks',
      }, {
        text: 'Контент',
        path: 'content',
        value: 'content',
      },
    ],
  }),
  computed: {
    activeTabNames() {
      const filteredTabNames = this.tabNames.filter((item) => this[`${item.value}Data`]?.length > 0);
      if (filteredTabNames.length > 1) {
        filteredTabNames.unshift(this.tabNames[0]);
      }
      return filteredTabNames;
    },
    showLevels() {
      return (this.selectedTab === 'all' || this.selectedTab === 'levels') && this.levelsData.length > 0;
    },
    showTasks() {
      return (this.selectedTab === 'all' || this.selectedTab === 'tasks') && this.tasksData.length > 0;
    },
    showContent() {
      return (this.selectedTab === 'all' || this.selectedTab === 'content') && this.contentData.length > 0;
    },
    searchText() {
      return this.$route.query.search;
    },
    parentLevel() {
      return this.searchResult.parent_level || {};
    },
    parentLocation() {
      return this.searchResult.parent_location || {};
    },
    parentTask() {
      return this.searchResult.parent_task || {};
    },
    isEmpty() {
      return this.levelsData.length === 0
        && this.tasksData.length === 0
        && this.contentData.length === 0;
    },
  },
  watch: {
    '$route.query.search': {
      immediate: true,
      handler(val) {
        this.getSearchResult(val);
      },
    },
  },

  methods: {
    async getSearchResult(value) {
      try {
        if (!value) return;
        this.loading = true;
        const { data } = await snamiApi.contentSearch(value);
        this.searchResult = data.data;
        this.levelsData = this.serializeLevels(data.data.level);
        this.tasksData = this.serialiseTasks(data.data.task);
        this.contentData = this.serialiseContent(data.data.content);
      } catch (e) {
        console.warn(e);
      }
      this.loading = false;
    },
    serializeLevels(data = []) {
      return data.map((level) => {
        const location = this.getLocation(level);
        return {
          ...level,
          location,
        };
      });
    },
    serialiseTasks(data = []) {
      return data.map((task) => {
        const level = this.getLevel(task);
        const location = this.getLocation(level);
        return {
          level: {
            ...level,
            location,
          },
          ...task,
        };
      });
    },
    serialiseContent(data = []) {
      return data.map((content) => {
        const task = this.getTask(content);
        const level = this.getLevel(task);
        const location = this.getLocation(level);
        return {
          task: {
            ...task,
            level: {
              ...level,
              location,
            },
          },
          ...content,
        };
      });
    },
    getLevel(level = {}) {
      return this.parentLevel[level.level_id] || {};
    },
    getLocation(location = {}) {
      return this.parentLocation[location.location_id] || {};
    },
    getTask(task = {}) {
      return this.parentTask[task.task_id] || {};
    },
  }
  ,
};
</script>
<style lang="scss">

</style>
