<template>
  <VListItem
    class="pa-0"
  >
    <VListItemAvatar class="align-self-start mt-3">
      <VAvatar
        size="36"
        :color="`${iconColor} pale`"
      >
        <VIcon
          small
          :color="`${iconColor} base`"
        >
          {{ icon }}
        </VIcon>
      </VAvatar>
    </VListItemAvatar>

    <VListItemContent>
      <VListItemTitle
        v-if="$slots.title"
        class="tt-light-mono-46--text"
      >
        <slot name="title" />
      </VListItemTitle>
      <VListItemSubtitle
        v-if="$slots.subtitle"
        class="tt-text-body-2"
      >
        <slot name="subtitle" />
      </VListItemSubtitle>
      <div v-if="$slots.content">
        <slot name="content" />
      </div>
    </VListItemContent>
  </VListItem>
</template>
<script>
export default {
  name: 'SearchListItem',
  props: {
    icon: {
      type: String,
      default: 'fal fa-project-diagram',
    },
    iconColor: {
      type: String,
      default: 'tt-light-green',
    },
  },
};
</script>
